
import { defineComponent, PropType } from "vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import { sortOrders } from "@/enums/main/sortOrders";
export default defineComponent({
  name: "SortButton",
  components: { SvgIcon },
  props: {
    sort: {
      type: String as PropType<
        sortOrders.asc | sortOrders.desc | sortOrders.turnOff
      >,
      default: sortOrders.turnOff
    }
  }
});
